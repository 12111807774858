import { PageEvents as waitUntil } from "./page_events"

class Components {
  static #components = []
  static #modules = import.meta.glob("../../components/**/*.js")

  static async call() {
    await this.#importAll()
    await waitUntil.domLoaded()
    this.#callAll()
    this.#listenForPageChange()
  }

  static #importAll() {
    const importPromises = Object.values(this.#modules).map(moduleImport => {
      return moduleImport().then(module => {
        if (module.default) {
          this.#components.push(module.default)
        }
      })
    })

    return Promise.all(importPromises)
  }

  static #callAll() {
    this.#components.forEach(this.#callComponent)
  }

  static #callComponent(component) {
    const instance = new component
    instance.call()
  }

  static #listenForPageChange() {
    document.documentElement.addEventListener(
      "turbo:render",
      () => this.#callAll()
    )
  }
}

Components.call()
