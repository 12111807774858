export class PageEvents {
  static domLoaded() {
    return new Promise((resolve) => {
      if (document.readyState === "loading") {
        document.addEventListener('DOMContentLoaded', function() {
          resolve()
        })
      } else {
        resolve()
      }
    })
  }

  static pageLoaded() {
    return new Promise((resolve) => {
      if (document.readyState === "complete") {
        resolve()
      } else {
        document.onreadystatechange = function () {
          if (document.readyState === "complete") {
            resolve()
          }
        }
      }
    })
  }
}
